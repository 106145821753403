<template>
    <div id="VideoPersonalize">
        <canvas id="canvas" width="1920" height="1080"/>
    </div>
</template>

<script>

export default {
    name: "VideoPersonalize",

    props: {
        personalization: {
            type: Object,
            required: true
        }
    },

    mounted() {
        this.$nextTick().then(this.compose)

        // this.send()
    },

    methods: {
        async send() {
            let form = await this.record();

            return window.axios.post('api/compile-video', form, {headers: {"Content-Type": "multipart/form-data"}});
        },

        compose() {
            let vm = this;
            /* eslint no-undef: 0 */ // --> OFF

            (function (cjs, an) {
                cjs = window.createjs;

                var p; // shortcut to reference prototypes
                var lib = {};
                var ss = {};
                var img = {};
                lib.ssMetadata = [];


                (lib.AnMovieClip = function () {
                    this.actionFrames = [];
                    this.gotoAndPlay = function (positionOrLabel) {
                        cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
                    }
                    this.play = function () {
                        cjs.MovieClip.prototype.play.call(this);
                    }
                    this.gotoAndStop = function (positionOrLabel) {
                        cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
                    }
                    this.stop = function () {
                        cjs.MovieClip.prototype.stop.call(this);
                    }
                }).prototype = p = new cjs.MovieClip();


                (lib.logo = function () {
                    this.initialize(vm.personalization.logo_url);
                }).prototype = p = new cjs.Bitmap();
                p.nominalBounds = new cjs.Rectangle(0, 0, 265, 265);// helper functions:

                function mc_symbol_clone() {
                    var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
                    clone.gotoAndStop(this.currentFrame);
                    clone.paused = this.paused;
                    clone.framerate = this.framerate;
                    return clone;
                }

                function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
                    var prototype = cjs.extend(symbol, cjs.MovieClip);
                    prototype.clone = mc_symbol_clone;
                    prototype.nominalBounds = nominalBounds;
                    prototype.frameBounds = frameBounds;
                    return prototype;
                }

                (lib.Tween3 = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.shape = new cjs.Shape();
                    this.shape.graphics.beginFill(vm.personalization.color).beginStroke().moveTo(-188.3, 24.1).lineTo(-188.3, -24.1).lineTo(188.3, -24.1).lineTo(188.3, 24.1).closePath();
                    this.shape.setTransform(0.025, 0);

                    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

                    this._renderFirstFrame();

                }).prototype = p = new cjs.MovieClip();

                p.nominalBounds = new cjs.Rectangle(-188.2, -24, 376.5, 48.1);


                (lib.Tween1 = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.shape = new cjs.Shape();
                    this.shape.graphics.beginFill(vm.personalization.color).beginStroke().moveTo(-188.3, 45.1).lineTo(-188.3, -45.1).lineTo(188.3, -45.1).lineTo(188.3, 45.1).closePath();
                    this.shape.setTransform(0.025, 0.025);

                    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

                    this._renderFirstFrame();

                }).prototype = p = new cjs.MovieClip();
                p.nominalBounds = new cjs.Rectangle(-188.2, -45, 376.5, 90.1);


                (lib.sq_1 = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.shape = new cjs.Shape();
                    this.shape.graphics.beginFill(vm.personalization.color).beginStroke().moveTo(-188.3, 45.1).lineTo(-188.3, -45.1).lineTo(188.3, -45.1).lineTo(188.3, 45.1).closePath();
                    this.shape.setTransform(188.275, 45.075);

                    this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.sq_1, new cjs.Rectangle(0, 0, 376.6, 90.2), null);


                (lib.phone = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.text = new cjs.Text(vm.personalization.phone, "bold 46px 'Helvetica'", vm.personalization.color);
                    this.text.lineHeight = 48;
                    this.text.parent = this;
                    this.text.setTransform(2, 2);

                    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.phone, new cjs.Rectangle(0, 0, 331.2, 50), null);


                (lib.logo_1 = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.instance = new lib.logo();

                    setTimeout(() => {
                        let ratio = {
                            ratio: this.instance.image.naturalWidth / this.instance.image.naturalHeight,
                            width: 265 / this.instance.image.naturalWidth,
                            height: 265 / this.instance.image.naturalHeight
                        };

                        this.instance.setTransform(63, 27, 0.7 * ratio.width * ratio.ratio, 0.7 * ratio.height);

                        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

                        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

                        this._renderFirstFrame();
                    }, 100);

                }).prototype = getMCSymbolPrototype(lib.logo_1, new cjs.Rectangle(63, 27, 190, 190), null);


                (lib.full_name = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.text = new cjs.Text(vm.personalization.name, "bold 69px 'Helvetica'", "#333333");
                    this.text.lineHeight = 71;
                    this.text.parent = this;
                    this.text.setTransform(2, 2);

                    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.full_name, new cjs.Rectangle(0, 0, 544.4, 73), null);


                (lib.email = function (mode, startPosition, loop, reversed) {
                    if (loop == null) {
                        loop = true;
                    }
                    if (reversed == null) {
                        reversed = false;
                    }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this, [props]);

                    // Layer_1
                    this.text = new cjs.Text(vm.personalization.email, "bold 32px 'Helvetica'", vm.personalization.color);
                    this.text.lineHeight = 34;
                    this.text.parent = this;
                    this.text.setTransform(2, 2);

                    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.email, new cjs.Rectangle(0, 0, 416.9, 36), null);


                (lib.company_name = function(mode,startPosition,loop,reversed) {
                    if (loop == null) { loop = true; }
                    if (reversed == null) { reversed = false; }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this,[props]);

                    // Layer_1
                    this.text = new cjs.Text(vm.personalization.company, "bold 40px 'Helvetica'");
                    this.text.lineHeight = 40;
                    this.text.parent = this;
                    this.text.setTransform(2.1,-14.65);

                    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.company_name, new cjs.Rectangle(0.1,-16.6,306.09999999999997,54), null);


                (lib.company = function(mode,startPosition,loop,reversed) {
                    if (loop == null) { loop = true; }
                    if (reversed == null) { reversed = false; }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this,[props]);

                    // Layer_1
                    this.text = new cjs.Text(vm.personalization.job_title, "bold 32px 'Helvetica'");
                    this.text.lineHeight = 32;
                    this.text.parent = this;
                    this.text.setTransform(2.05,15.45);

                    this.timeline.addTween(cjs.Tween.get(this.text).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.company, new cjs.Rectangle(0.1,13.5,126.5,36), null);


                (lib.job_title = function(mode,startPosition,loop,reversed) {
                    if (loop == null) { loop = true; }
                    if (reversed == null) { reversed = false; }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this,[props]);

                    // Layer_1
                    this.instance = new lib.company();
                    this.instance.setTransform(249.6,18,1,1,0,0,0,249.6,18);

                    this.timeline.addTween(cjs.Tween.get(this.instance).wait(1));

                    this._renderFirstFrame();

                }).prototype = getMCSymbolPrototype(lib.job_title, new cjs.Rectangle(0.1,13.5,126.5,36), null);


// stage content:
                (lib.test_template = function(mode,startPosition,loop,reversed) {
                    if (loop == null) { loop = false; }
                    if (reversed == null) { reversed = false; }
                    var props = new Object();
                    props.mode = mode;
                    props.startPosition = startPosition;
                    props.labels = {};
                    props.loop = loop;
                    props.reversed = reversed;
                    cjs.MovieClip.apply(this,[props]);

                    // logo
                    this.instance = new lib.logo_1();
                    this.instance.setTransform(414.75,842.35,0.4165,0.4165,0,0,0,123.2,123.2);
                    this.instance.alpha = 0.0117;
                    this.instance._off = true;

                    this.timeline.addTween(cjs.Tween.get(this.instance).wait(46).to({_off:false},0).to({scaleX:1,scaleY:1,alpha:1},9,cjs.Ease.quadOut).wait(245));

                    // square_2
                    this.instance_1 = new lib.Tween1();
                    this.instance_1.setTransform(290.45,315.4,0.3774,0.3774);
                    this.instance_1.alpha = 0.0117;

                    this.instance_2 = new lib.sq_1();
                    this.instance_2.setTransform(290.65,315.45,0.928,0.928,0,0,0,188.5,45.1);
                    this.instance_2._off = true;

                    this.timeline.addTween(cjs.Tween.get(this.instance_1).to({_off:true,regX:188.5,regY:45.1,scaleX:0.928,scaleY:0.928,x:290.65,y:315.45,alpha:1},9,cjs.Ease.quadOut).wait(291));
                    this.timeline.addTween(cjs.Tween.get(this.instance_2).to({_off:false},9,cjs.Ease.quadOut).to({regX:188.7,regY:45.2,scaleX:0.6556,scaleY:0.6556,x:290.75,y:315.55},5,cjs.Ease.quadOut).to({regX:188.5,regY:45.1,scaleX:0.928,scaleY:0.928,x:290.65,y:315.45},6,cjs.Ease.quadIn).wait(280));

                    // square
                    this.instance_3 = new lib.Tween3();
                    this.instance_3.setTransform(410.45,194.35,0.3938,0.3938);
                    this.instance_3.alpha = 0.0117;
                    this.instance_3._off = true;

                    this.timeline.addTween(cjs.Tween.get(this.instance_3).wait(8).to({_off:false},0).to({scaleX:1,scaleY:1,alpha:1},11,cjs.Ease.quadOut).to({scaleX:0.6861,scaleY:0.6861},7,cjs.Ease.quadIn).to({scaleX:1,scaleY:1},7,cjs.Ease.quadOut).wait(267));

                    // company
                    this.instance_4 = new lib.company_name();
                    this.instance_4.setTransform(767.5,333.25,1,1,0,0,0,249.6,18);
                    this.instance_4.alpha = 0.0117;
                    this.instance_4._off = true;
                    new cjs.ButtonHelper(this.instance_4, 0, 1, 1);

                    this.timeline.addTween(cjs.Tween.get(this.instance_4).wait(19).to({_off:false},0).to({x:847.5,alpha:1},12,cjs.Ease.quadOut).wait(269));

                    // fulll_name
                    this.instance_5 = new lib.full_name();
                    this.instance_5.setTransform(790.1,411.75,1,1,0,0,0,272.2,36.5);
                    this.instance_5.alpha = 0.0117;
                    this.instance_5._off = true;
                    new cjs.ButtonHelper(this.instance_5, 0, 1, 1);

                    this.timeline.addTween(cjs.Tween.get(this.instance_5).wait(24).to({_off:false},0).to({x:870.1,alpha:1},12,cjs.Ease.quadOut).wait(264));

                    // job_title
                    this.instance_6 = new lib.job_title();
                    this.instance_6.setTransform(662.8,493.25,1,1,0,0,0,144.9,18);
                    this.instance_6.alpha = 0.0117;
                    this.instance_6._off = true;

                    this.timeline.addTween(cjs.Tween.get(this.instance_6).wait(29).to({_off:false},0).to({x:742.8,alpha:1},12,cjs.Ease.quadOut).wait(259));

                    // phone
                    this.instance_7 = new lib.phone();
                    this.instance_7.setTransform(684.25,594.25,1,1,0,0,0,165.6,25);
                    this.instance_7.alpha = 0.0117;
                    this.instance_7._off = true;
                    new cjs.ButtonHelper(this.instance_7, 0, 1, 1);

                    this.timeline.addTween(cjs.Tween.get(this.instance_7).wait(34).to({_off:false},0).to({x:764.25,alpha:1},12,cjs.Ease.quadOut).wait(254));

                    // email
                    this.instance_8 = new lib.email();
                    this.instance_8.setTransform(725.3,649.8,1,1,0,0,0,208.4,18);
                    this.instance_8.alpha = 0.0117;
                    this.instance_8._off = true;

                    this.timeline.addTween(cjs.Tween.get(this.instance_8).wait(39).to({_off:false},0).to({x:805.3,alpha:1},12,cjs.Ease.quadOut).wait(249));

                    // bg
                    this.shape = new cjs.Shape();
                    this.shape.graphics.beginFill("#FFFFFF").beginStroke().moveTo(-1021.5,566.9).lineTo(-1021.5,-566.9).lineTo(1021.5,-566.9).lineTo(1021.5,566.9).closePath();
                    this.shape.setTransform(960,540.025);

                    this.timeline.addTween(cjs.Tween.get(this.shape).wait(300));

                    this._renderFirstFrame();

                }).prototype = p = new lib.AnMovieClip();

                p.nominalBounds = new cjs.Rectangle(898.5,513.2,1083,593.7);
// library properties:
                lib.properties = {
                    id: '619646C2E6DB4CEB912ED1DBEB78D16E',
                    width: 1920,
                    height: 1080,
                    fps: 30,
                    color: "#FFFFFF",
                    opacity: 1.00,
                    manifest: [
                        {src: vm.personalization.logo_url, id: "logo"}
                    ],
                    preloads: []
                };

// bootstrap callback support:

                (lib.Stage = function (canvas) {
                    createjs.Stage.call(this, canvas);
                }).prototype = p = new createjs.Stage();

                p.setAutoPlay = function (autoPlay) {
                    this.tickEnabled = autoPlay;
                }
                p.play = function () {
                    this.tickEnabled = true;
                    this.getChildAt(0).gotoAndPlay(this.getTimelinePosition())
                }
                p.stop = function (ms) {
                    if (ms) this.seek(ms);
                    this.tickEnabled = false;
                }
                p.seek = function (ms) {
                    this.tickEnabled = true;
                    this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000);
                }
                p.getDuration = function () {
                    return this.getChildAt(0).totalFrames / lib.properties.fps * 1000;
                }

                p.getTimelinePosition = function () {
                    return this.getChildAt(0).currentFrame / lib.properties.fps * 1000;
                }

                an.bootcompsLoaded = an.bootcompsLoaded || [];
                if (!an.bootstrapListeners) {
                    an.bootstrapListeners = [];
                }

                an.bootstrapCallback = function (fnCallback) {
                    an.bootstrapListeners.push(fnCallback);
                    if (an.bootcompsLoaded.length > 0) {
                        for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
                            fnCallback(an.bootcompsLoaded[i]);
                        }
                    }
                };

                an.compositions = an.compositions || {};
                an.compositions['619646C2E6DB4CEB912ED1DBEB78D16E'] = {
                    getStage: function () {
                        return exportRoot.stage;
                    },
                    getLibrary: function () {
                        return lib;
                    },
                    getSpriteSheet: function () {
                        return ss;
                    },
                    getImages: function () {

                        console.log(img);

                        return img;
                    }
                };

                an.compositionLoaded = function (id) {
                    an.bootcompsLoaded.push(id);
                    for (var j = 0; j < an.bootstrapListeners.length; j++) {
                        an.bootstrapListeners[j](id);
                    }
                }

                an.getComposition = function (id) {
                    return an.compositions[id];
                }

                an.handleSoundStreamOnTick = function (event) {
                    if (!event.paused) {
                        var stageChild = stage.getChildAt(0);
                        if (!stageChild.paused) {
                            stageChild.syncStreamSounds();
                        }
                    }
                }


            })(createjs = window.createjs || {}, AdobeAn = AdobeAn || {});

            var createjs, AdobeAn;

            var canvas, stage, exportRoot, fnStartAnimation;

            function init() {
                canvas = document.getElementById("canvas");
                // anim_container = document.getElementById("animation_container");
                // dom_overlay_container = document.getElementById("dom_overlay_container");
                var comp = AdobeAn.getComposition("619646C2E6DB4CEB912ED1DBEB78D16E");
                comp.getLibrary();
                handleComplete({}, comp);
            }

            function handleComplete(evt, comp) {
                //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
                var lib = comp.getLibrary();
                comp.getSpriteSheet();
                exportRoot = new lib.test_template();
                stage = new lib.Stage(canvas);
                stage.enableMouseOver();
                //Registers the "tick" event listener.
                fnStartAnimation = function () {
                    stage.addChild(exportRoot);
                    createjs.Ticker.framerate = lib.properties.fps;
                    createjs.Ticker.addEventListener("tick", stage);
                }
                //Code to support hidpi screens and responsive scaling.
                // AdobeAn.makeResponsive(true,'both',true,1,[canvas,anim_container,dom_overlay_container]);
                AdobeAn.compositionLoaded(lib.properties.id);
                fnStartAnimation();
            }

            init();
        },

        record() {
            let recordedChunks = [];

            return new Promise(function (res) {
                let canvas = document.querySelector('#canvas');

                let stream = canvas.captureStream(30);

                let mediaRecorder = new MediaRecorder(stream, {
                    mimeType: "video/webm; codecs=vp9"
                });

                //ondataavailable will fire in interval of `time || 4500 ms`
                mediaRecorder.start(4500);

                mediaRecorder.ondataavailable = () => {
                    recordedChunks.push(event.data);
                    if (mediaRecorder.state === 'recording') {
                        mediaRecorder.stop();
                    }
                }

                mediaRecorder.onstop = function () {
                    let blob = new Blob(recordedChunks, {type: "video/webm"});
                    let form = new FormData();
                    form.append('video', new File([blob], "name.webm"))
                    res(form);
                }
            })
        }
    }
}
</script>

<style scoped>
#contentVideo {
    /*visibility: hidden;*/
    /*position: absolute;*/
}
</style>
