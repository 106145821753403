<template>
    <user-dashboard-layout :loading="loading">

        <v-container v-if="recordVideo" style="height: 300px;">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
                <v-col class="subtitle-1 text-center" cols="12">
                    {{$t('other.' + other.updpr)}}
                </v-col>
                <v-col cols="6">
                    <v-progress-linear
                        color="primary accent-4"
                        indeterminate
                        rounded
                        height="10"
                    ></v-progress-linear>
                </v-col>
            </v-row>
        </v-container>

        <v-container v-show="!recordVideo" fluid class="fill-height p-5">
            <v-row>
                <v-col>
                    <v-card  class="mx-auto my-auto" >
                        <v-card-text>
                            <VideoPersonalize v-if="this.recordVideo" ref="videoPersonalize" :personalization="personalization" class="hidden"/>

                            <v-row>
                                <h3>{{$t('other.' + other.phtlg)}}</h3>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3>
                                        {{$t('other.' + other.lg)}}
                                        <v-btn x-small v-if="personalization.logo_url" @click="personalization.logo_url = null">{{$t('other.' + other.rmv)}}</v-btn>
                                        <v-btn x-small v-if="personalization.logo_url" @click="personalization.logo_url = 'updatePersonalization'">{{$t('other.' + other.btnupd)}}</v-btn>
                                    </h3>
                                    <avatar-edit
                                        :current_url="personalization.logo_url"
                                        v-model="personalization.logo_url"
                                    ></avatar-edit>
                                </v-col>
                                <v-col>
                                    <h3>
                                        {{$t('other.' + other.pht)}}
                                        <v-btn x-small v-if="user.avatar_url" @click="dropAvatar">{{$t('other.' + other.rmv)}}</v-btn>
                                        <v-btn x-small v-if="user.avatar_url" @click="user.avatar_url = 'updateAvatar'">{{$t('other.' + other.btnupd)}}</v-btn>
                                    </h3>
                                    <avatar-edit
                                        :current_url="user.avatar_url"
                                        @input="handleUpdateProfile"
                                        v-model="user.avatar_url"
                                    ></avatar-edit>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-form>
                                        <v-container>
                                            <v-row>
                                                <h3>{{$t('other.' + other.persinf)}}</h3>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.name" label="Full Name"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.company" label="Company"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-model="personalization.location" label="Location"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <h3>{{$t('other.' + other.continf)}}</h3>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.phone" label="Phone"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-model="personalization.email" label="Email"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.website" label="Website"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-model="personalization.job_title" label="Job Title"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <h3>{{$t('other.' + other.socpr)}}</h3>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.twitter_name" label="Twitter"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                                <v-col>
                                                    <v-text-field v-model="personalization.facebook_name" label="Facebook"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <h3>{{$t('other.' + other.bcinf)}}</h3>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-textarea v-model="personalization.bio" label="Bio"
                                                                placeholder=""></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-text-field v-model="personalization.registered_id" label="Registered Id"
                                                                  placeholder=""></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="col-auto">
                                                    <input id="color" type="color" v-model="personalization.color">
                                                </v-col>
                                                <v-col>
                                                    <label for="color" v-text="personalization.color"/>
                                                </v-col>
                                                <v-btn @click="getFacebookToken" class="mr-4">{{$t('other.' + other.btnfcb)}}</v-btn>
                                            </v-row>
                                            <v-row>
                                                <v-col>
                                                    <v-btn @click="handleUpdatePersonalization">{{$t('other.' + other.btnupd)}}</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-form>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
import UserDashboardLayout from "../../layouts/UserDashboardLayout";
import User from "../../models/User";
import AvatarEdit from "../../components/AvatarEdit";
import Personalization from "../../models/Personalization";
import {mapMutations} from "vuex";
import VideoPersonalize from "@/components/video/personalize";
import other from "@/configs/other";

export default {
    name: "Personalization",
    components: {VideoPersonalize, AvatarEdit, UserDashboardLayout},
    data: function () {
        return {
            user: {},
            personalization: {},
            recordVideo: false,
            loading: false,
            other
        }
    },
    methods: {
        async handleUpdatePersonalization() {
            window.onbeforeunload = true;
            this.loading = true;
            const personalization = new Personalization(this.personalization);
            this.personalization = await personalization.save();
            this.recordVideo = true;
            this.loading = false;

            this.$nextTick(async () => {
                await this.$refs.videoPersonalize.send();
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Profile Saved'});
                this.recordVideo = false;

                window.onbeforeunload = null;
            });
        },

        async handleUpdateProfile() {
            const user = new User(this.user);
            this.user = await user.save();
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Profile Saved'});
        },

        getFacebookToken() {
            window.axios.get('api/fb/bind?redirect_url=' + window.location.href).then((response) => {
                window.location.href = response.data;
            })
        },

        dropAvatar() {
            this.user.avatar_url = null;
            this.handleUpdateProfile();
        },
        ...mapMutations(['showSnackBar']),
    },
    mounted() {
        this.loading = true;
        this.user = this.$auth.user();

        setTimeout(async () => {
            const user = await User.include(['personalization']).find(this.$auth.user().id);
            this.personalization = user.personalization;
            this.loading = false;
        }, 900)
    },
    watch: {
        "personalization.color": function (val) {
            if (typeof val == "object") {
                this.personalization.color = val.hex;
            }
        }
    }
}
</script>

<style scoped>
    .hidden {
        /*visibility: hidden;*/
        /*position: absolute;*/
        /*z-index: -9999;*/
    }
</style>
